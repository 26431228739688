import React, { Component } from "react";
import Stopwatch from "./components/Stopwatch";
import Webcam from "react-webcam";

export class App extends Component {
  render() {
    const videoConstraints = {
      width: { min: 480 },
      height: { min: 720 },
      aspectRatio: 0.6666666667,
    };

    return (
      <div>
        <Stopwatch />
        {console.log()}
        <div style={{ background: "#000" }}>
          <Webcam
            videoConstraints={videoConstraints}
            width={480}
            height={720}
          />
        </div>
        test
      </div>
    );
  }
}

export default App;
